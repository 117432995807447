import React, { useEffect, useState } from "react";
import "./Student.css";
import axios from "axios";
import { Link } from "react-router-dom";
function Student() {
  const [student, setStudent] = useState([]);
  console.log(student);
  useEffect(() => {
    axios
      .get("https://back.firaoltegene.com.et")
      .then((res) => setStudent(res.data))
      .catch((err) => console.log(err));
  }, []);
  const handleClick = async (id) => {
    try {
      await axios.delete("https://back.firaoltegene.com.et/student/" + id);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className=" pt-20 h-screen flex justify-center items-center bg-green-500">
      <div className="w-3/4 mx-4 bg-white py-2 px-4 ">
        <Link to="/create" className="rounded bg-black text-white ">
          add +
        </Link>
        <table className="table-fixed">
          <thead>
            <tr>
              <th>name</th>
              <th>age</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {student.map((data, i) => (
              <tr key={i}>
                <td>{data.name}</td>
                <td>{data.age}</td>
                <Link
                  to={`update/${data.id}`}
                  className="rounded bg-black text-white mx-2 py-1 px-2 "
                >
                  update
                </Link>
                <button
                  className="rounded bg-black text-white py-1 px-2"
                  type=""
                  onClick={(e) => handleClick(data.id)}
                >
                  delete
                </button>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Student;
