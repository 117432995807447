import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Createuser from "./Createuser";
import Updateuser from "./Updateuser";
import Student from "./Student";
function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path="/create" element={<Createuser />} />
            <Route path="/update/:id" element={<Updateuser />} />
            <Route path="/" element={<Student />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
