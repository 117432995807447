import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Createuser() {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const navigate = useNavigate();

  const handleclick = (e) => {
    e.preventDefault();
    axios
      .post("https://back.firaoltegene.com.et/create", { name, age })
      .then((res) => {
        console.log(res);
        navigate("/");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex justify-center bg-red-400  h-screen">
      <div className="bg-white h-60 w-80 items-center mt-32 pt-12">
        <form className="gap-4" onSubmit={handleclick}>
          <label>name: </label>
          <input
            type="text"
            placeholder="name"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <br />
          <label>age: </label>
          <input
            type="number"
            placeholder="age"
            className="ml-2"
            onChange={(e) => {
              setAge(e.target.value);
            }}
          />

          <button className="my-4 bg-red-400 hover:bg-red-700 text-white rounded rounded-fully px-2">
            submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Createuser;
